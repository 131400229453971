<template>
	<el-row class="token-bind">
		<div class="form-center">
			<el-form :model="createFormData" :rules="createFormDataRules" label-position='top'
			         ref="bindForm" label-width="100px" class="demo-ruleForm" size="small">
				<el-form-item label="频道选择" prop="youtubeChannelId">
					<el-select v-model="createFormData.youtubeChannelId" @change="handleChannelChange" placeholder="请选择频道">
						<el-option :key="channel.id" :label="channel.channelName" :value="channel.id" v-for="channel in youTubeChannels"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="密钥选择" prop="clientSecretId">
					<el-select v-model="createFormData.clientSecretId" @change="handleChannelChange" placeholder="请选择密钥">
						<el-option :key="secret.id" :label="secret.secretName" :value="secret.id" v-for="secret in clientSecrets"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button :loading="bindingLoading" @click="toCreateNewBinding('bindForm')" type="primary">建立新绑定</el-button>
				</el-form-item>
			</el-form>
		</div>

		<div class="form-center">
			<div class="token-key" v-if="bindUrl">
				<el-link :href="bindUrl" type="danger" target="_blank">点击此链接进行绑定，请确保绑定正确的频道</el-link>
				<el-input v-model="bindKey" placeholder="请输入KEY" size="small"></el-input>
				<el-button :loading="updateLoading" @click="toUpdateChannelToken()" type="primary" size="small">确定输入</el-button>
			</div>
		</div>
	</el-row>
</template>

<script>
import {getAllYouTubeChannels, createNewBinding, updateChannelToken} from "@/api/channel";
import {getAllSecrets} from "@/api/client_secret";

export default {
	name: "OauthTokenBind",
	data() {
		return {
			youTubeChannels: [],
			clientSecrets: [],
			createFormData: {youtubeChannelId: "", clientSecretId: ""},
			createFormDataRules: {
				youtubeChannelId: [{required: true, message: '请选择频道', trigger: 'change'}],
				clientSecretId: [{required: true, message: '请选择密钥', trigger: 'change'}],
			},
			bindingLoading: false,
			updateLoading: false,
			bindUrl: "",
			bindKey: ""
		}
	},
	created() {
		this.toGetAllYouTubeChannels();
		this.toGetAllClientSecrets();
	},
	methods: {
		toGetAllYouTubeChannels() {
			getAllYouTubeChannels().then(response => {
				if (!response['success']) return this.$message.error('获取YouTube频道失败：' + response.data);
				this.youTubeChannels = response.data;
			}).catch((error) => {
				this.$message.error('获取YouTube频道失败：' + error);
			});
		},
		toGetAllClientSecrets() {
			getAllSecrets().then(response => {
				if (!response['success']) return this.$message.error('获取所有Secrets：' + response.data);
				this.clientSecrets = response.data;
			}).catch((error) => {
				this.$message.error('获取所有Secrets：' + error);
			});
		},
		toCreateNewBinding(formName) {
			this.$refs[formName].validate((valid) => {
				if (!valid) return false;
				this.bindingLoading = true;
				createNewBinding(this.createFormData).then(response => {
					this.bindingLoading = false;
					if (!response['success']) return this.$message.error('创建OauthToken绑定失败：' + response.data);
					this.bindUrl = response.data.bindUrl;
				}).catch((error) => {
					this.$message.error('创建OauthToken绑定失败：' + error);
					this.bindingLoading = false;
				});
			});
		},
		toUpdateChannelToken() {
			if (!this.bindKey) return this.$message.warning('请先输入绑定KEY');
			const keyData = {
				youtubeChannelId: this.createFormData.youtubeChannelId,
				clientSecretId: this.createFormData.clientSecretId,
				key: this.bindKey
			}
			this.updateLoading = true;
			updateChannelToken(keyData).then(response => {
				this.updateLoading = false;
				if (!response['success']) return this.$message.error('更新绑定失败：' + response.data);
				this.$message.success('更新成功~');
			}).catch((error) => {
				this.updateLoading = false;
				this.$message.error('更新绑定失败：' + error);
			});
		},
		handleChannelChange() {
			this.bindUrl = "";
			this.bindKey = "";
		}
	}
}
</script>

<style lang="less" scoped>
.token-bind {
	padding: 20px 10px;

	.form-center {
		max-width: 600px;
		margin: 10px auto;

		.el-form-item__content {
			.el-select {
				width: 100%;
			}
		}
	}

	.token-key {
		margin-top: 10px;

		.el-link {
			margin: 10px 0;
		}

		.el-input {
			margin: 10px 0;
		}
	}
}
</style>